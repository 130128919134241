.gallery-content {
	display: flex;
	justify-content: center;
}
.gallery-div {
	margin: 0.5em;
	width: 100%;
}

.gallery-div-img {
	display: flex;
	justify-content: center;
}
.gall-img {
	object-fit: contain;
	width: 10em;
	height: 10em;
}
.gall-img-title {
	text-align: center;
	font-size: 1em;
	font-family: artifiko, serif;
}
