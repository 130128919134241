.project-filter {
	text-align: center;
}
.project-filter nav {
	margin-top: 2em;
	font-size: 1.3em;
}

.project-filter nav button {
	margin-right: 1em;
	background: transparent;
	border: 0;
	font-family: inherit;
	font-weight: bold;
	color: var(--text-color);
	cursor: pointer;
	border-right: 1px solid #183918;
	font-size: 0.9em;
}
.project-filter button.active {
	color: var(--bg-color);
}
