footer {
	display: flex;
	flex-direction: column;
	height: 5em;
	width: 100%;
	background-color: var(--bg-color);
	color: var(--highlight-color);
}
.footerP {
	margin: auto;
	font-size: 1em;
}
.footerP2 {
	margin: auto;
	font-size: 0.8em;
}
.footerP3 {
	margin: auto;
	margin-bottom: 2em;
	font-size: 0.8em;
}
