.GalletyDetails-img {
	width: 90%;
	margin: 2em 0;
}
.GalletyDetails-content {
	text-align: center;
	justify-content: center;
}
.detailsIcon {
	cursor: pointer;
	color: red;
	font-size: 5em;
}
.galleryDetailsBack {
	display: flex;
	justify-content: end;
	padding: 1em;
}
