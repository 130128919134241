header {
	height: 5em;
	width: 100%;
	background-color: var(--bg-color);
}
nav {
	height: inherit;
}
.navbar-brand {
	margin-right: auto;
	letter-spacing: 30px;
	/* font-family: artifiko, serif; */
	/* font-family: "Matemasie", sans-serif; */
	font-family: "Ga Maamli", sans-serif;
	font-size: 2.2em;
	color: var(--highlight-color);
}

.header-brand {
	flex-grow: 1;
	text-align: center;
}
.navbar-toggler {
	background-color: #242222;
}

.navbar-brand:hover {
	color: var(--text-color);
}

.nav-link:hover {
	color: var(--highlight-color);
	background-color: var(--text-color);
	border-radius: 15%;
}
.navbar-expand-md .offcanvas {
	flex-grow: 0;
}
.header-login {
	color: var(--highlight-color);
	margin-right: 1em;
}

@media (max-width: 768px) {
	.navbar-brand {
		font-size: 1.3em;
		letter-spacing: 10px;
	}
	.nav-link {
		text-align: center;
		color: var(--text-color);
		background-color: var(--highlight-color);
		margin-bottom: 2px;
	}
	.navbar-expand a:not(:first-child) {
		margin-left: 1.2em;
	}
	.navbar-expand a {
		font-size: 1em;
	}
}
