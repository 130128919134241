.main-content {
	margin: auto;
	width: 70%;
}
.home-img {
	width: 80%;
	margin-bottom: 3em;
}
.sidebar-l .navbar-nav {
	flex-direction: column;
	margin-left: 2em;
}

.sidebar-l-about,
.sidebar-r-about {
	padding: 0 1.5em;
}

@media (max-width: 768px) {
	.main-content {
		margin: auto;
		width: 90%;
	}
	.home-img {
		width: 90%;
		margin-bottom: 3em;
	}

	.sidebar-l .navbar-nav {
		flex-direction: row;
		justify-content: center;
	}
	.navbar-expand {
		justify-content: center;
	}
}
