@import url("https://fonts.googleapis.com/css2?family=Artifika&display=swap");
/* font-family: artifiko, serif; */

@import url("https://fonts.googleapis.com/css2?family=Ga+Maamli&family=Matemasie&display=swap");
/* font-family: "Matemasie", sans-serif; */

:root {
	--text-color: #786257;
	--danger-color: rgb(254, 15, 15);
	--highlight-color: #e3ccd2;
	--bg-color: #183918;
}

* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.body-app-content {
	min-height: calc(100vh - 160px);
	text-align: center;
	flex-direction: row;
	color: var(--text-color);
	background: radial-gradient(
		circle,
		rgba(241, 230, 233, 0.922) 47%,
		rgb(232, 213, 218) 100%
	);
}

.btn {
	margin-top: 2.5em;
	margin-bottom: 2.5em;
	background: var(--bg-color);
	padding: 8px 12px;
	border-radius: 4px;
	color: var(--highlight-color);
	cursor: pointer;
	font-size: 1em;
	border: 1px solid var(--primary-color);
}
.btn:hover {
	background: var(--highlight-color);
	color: var(--bg-color);
	cursor: pointer;
}

.error {
	color: var(--danger-color);
	background: var(--highlight-color);
	border: 1px solid var(--danger-color);
	border-radius: 4px;
	padding: 8px;
	margin: 10px 0;
}

ul {
	list-style-type: none;
}

h2 {
	text-align: center;
	padding: 1.3em 0;
	font-size: 1.2em;
	font-family: "artifika", "serif";
}
h3 {
	margin: 1.3em 0;
}

.form-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.row {
	--bs-gutter-x: 0;
}
