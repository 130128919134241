/* **************************************** */
/* estilos para la seccion del Redes Sociales */
/* ****************************************** */

.redes-sociales {
	/* background: #1e2b38; */
	padding: 15px 0;
}

a {
	text-decoration: none;
}

svg {
	vertical-align: text-top;
}
.redes-sociales .contenedor {
	display: flex;
	justify-content: center;
}

.redes-sociales a {
	color: #fff;
	text-align: center;
	/* height: 1.5em; */
	width: 1.1em;
	display: block;
	padding: 5px 0;
	border-radius: 3px;
	font-size: 3em;
	margin: 0 10px;
}

.redes-sociales .twitter {
	background: #1da1f2;
}
.redes-sociales .facebook {
	background: #3b5998;
}
.redes-sociales .messenger {
	background: #00b2ff;
}
.redes-sociales .whatsapp {
	background: #128c7e;
}
.redes-sociales .instagram {
	background: #f56040;
}
.redes-sociales .linkedln {
	background: #0e76a8;
}

.redes-sociales .twitter:hover {
	border-radius: 15px;
	color: white;
}
.redes-sociales .facebook:hover {
	border-radius: 15px;
	color: white;
}
.redes-sociales .messenger:hover {
	border-radius: 15px;
	color: white;
}
.redes-sociales .whatsapp:hover {
	border-radius: 15px;
	color: white;
}
.redes-sociales .instagram:hover {
	border-radius: 15px;
	color: white;
}
.redes-sociales .linkedln:hover {
	border-radius: 15px;
	color: white;
}

/* ************************************ */
/* estilos para la seccion del header */
/* ************************************ */
